.css-16y6h6u {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding: 2px !important;
}
.css-bgea9c {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding: 2px !important;
}

@media (min-width: 992px) {
  .css-lzmcx4 {
    margin-left: 0px !important;
  }
  .css-1yfne41-MuiTypography-root {
    font: size 0.65em !important;
  }
}
