/* .logoPadding {
  padding-top: 25px !important;
  padding-bottom: 15px !important;
} */
.css-rfb6dd {
  background: rgb(255, 121, 0) !important;
}

.css-1vt2rd0-MuiTypography-root {
  font-weight: 500 !important;
}

.css-1f1wq2x {
  background-color: #fff !important;
}

.css-wv2xt6 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

@media (max-width: 768px) {
  .css-rfb6dd {
    background: #fff !important;
  }

  .MuiBox-root {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}