@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600;700&display=swap");
:root {
  --heading-orange: rgba(255, 121, 0, 0.6);
  --text-body: rgba(10, 10, 10, 0.8);
  --text-light: rgba(255, 255, 255, 0.8);
  --black: rgba(10, 10, 10, 1);
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --haeding-font: "EB Garamond", serif;
}
.newsBox {
  overflow: hidden !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.news-card {
  min-width: 550px;
}
.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: transparent !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.125);
}
.news-header {
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ff7900 !important;
  font-family: var(--haeding-font) !important;
}
.card {
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important; */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  border: none !important;
}
.custom-card {
  background-color: rgba(10, 10, 10, 0.7) !important;
}
.clgName {
  color: #fff !important;
  text-transform: uppercase !important;
  font-family: var(--haeding-font) !important;
}
.about-h1 {
  font-family: var(--haeding-font) !important;
}
.esta {
  text-transform: capitalize !important;
  color: var(--text-light);
  font-style: italic;
  font-size: 14px;
  font-weight: 400;
}
.affiliation {
  color: var(--text-light) !important;
  font-weight: 300;
}
.affiliation span {
  color: var(--text-light) !important;
  font-weight: 500;
  text-transform: uppercase;
}
.address-text {
  color: var(--text-light) !important;
  font-size: 16px;
  padding-top: 15px;
}
.about-footer {
  position: absolute;
  left: 5%;
  bottom: 5%;
  border-left: 5px solid rgba(255, 255, 255, 0.8);
}
.about-footer h1 {
  color: rgba(255, 255, 255, 0.6);
}
.about-footer h5 {
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 16px;
  line-height: 22px;
  text-shadow: 0px 4px 6px rgba(255, 255, 255, 0.25);
}
#vision h1 {
  text-transform: capitalize;
  color: var(--black);
  font-family: "EB Garamond", serif;
  font-weight: 600;
}
#vision p {
  color: var(--text-body);
  font-size: 16px;
  font-weight: 400;
}
ul li::marker {
  color: var(--heading-orange) !important; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
  font-size: 20px;
}
#admission h1 {
  text-transform: capitalize;
  color: var(--black) !important;
  font-family: "EB Garamond", serif;
  font-weight: 600;
}
#admission p {
  color: var(--body-text) !important;
  font-size: 16px !important;
}
#course h1 {
  font-family: "EB Garamond", serif;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8) !important;
  line-height: 50px !important;
  font-size: 45px !important;
}
#courses h1 {
  font-family: "EB Garamond", serif;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8) !important;
  text-transform: capitalize;
  font-size: 45px !important;
}
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(255, 121, 0, 1) !important;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 0.5rem 1rem;
  font-size: 1.15rem;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
#courses a {
  color: var(--black) !important;
  font-weight: 700;
  font-size: 14px;
}
.course-box {
  border-left: 6px solid rgba(255, 255, 255, 0.4) !important;
}
.course-box ul li {
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.8) !important;
  line-height: 3;
  font-weight: 600;
  font-size: 22px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  cursor: pointer;
}
.course-box ul li:hover {
  transform: scale(1.3);
  transform-origin: 20% 40%;
}
.course-box ul li::marker {
  color: transparent !important;
}
.guru {
  color: var(--black);
  font-weight: 600;
  font-family: var(--haeding-font);
  line-height: 40px !important;
}
.guruimg {
  position: absolute !important;
  right: 30%;
  z-index: 0;
  opacity: 0.3;
}
.guru-text {
  color: var(--text-body) !important;
  font-size: 16px !important;
  line-height: 28px !important;
  text-align: justify;
  font-weight: 400;
}
#footer-bottom h2 {
  color: var(--text-light) !important;
  text-transform: capitalize !important;
  font-family: var(--haeding-font);
  font-size: 25px !important;
}
#footer-bottom p {
  color: var(--text-light);
  font-size: 14px;
}
.footer-head {
  font-family: var(--haeding-font) !important;
  color: rgba(255, 255, 255, 0.6) !important;
  font-size: 40px !important;
}
.btn-white {
  border-radius: 0 !important;
  padding: 10px 30px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
}
.fixed-top {
  position: fixed;
  top: 0;
}
.banner-head {
  color: var(--black);
  font-weight: 610;
  font-family: var(--haeding-font);
  line-height: 50px !important;
  /* text-transform: uppercase; */
}
.banner-sub {
  color: var(--black);
  font-weight: 600;
  font-family: var(--haeding-font);
  line-height: 10px !important;
}
.CollegeDetails {
  position: absolute;
  min-height: 310px;
  right: 5%;
  top: 0;
}
@media (max-width: 768px) {
  .MuiContainer-root {
    margin-left: 0px !important;
  }
  /* .MuiTypography-root {
    padding-left: 0px !important;
  } */
  .text-center-sm {
    text-align: center !important;
  }
  .banner-head {
    color: var(--black);
    font-weight: 610;
    font-family: var(--haeding-font);
    line-height: 40px !important;
    /* text-transform: uppercase; */
  }
  .bg-mob-about {
    background-image: url(../../assets/images/bg-about.webp);
    padding: 30px 0;
  }
  .news-card {
    min-width: 100%;
  }
  .text-mob-head {
    font-size: 12px !important;
  }
  .newsBox {
    overflow: hidden !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    margin-left: 10px !important;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
