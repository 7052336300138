@import url(https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600;700&display=swap);
.body-text {
  font-size: 16px;
  text-transform: none;
  text-align: justify;
}

.left-side {
  float: left;
  width: 40%;
  padding: 1%;
}

.loader {
  width: 180px !important;
}

.news-btn {
  background-color: #ff7900 !important;
  border-radius: 15px !important;
  padding: 5px 10px !important;
  color: #fff !important;
  font-size: 12px !important;
}

.footer-list {
  list-style: none !important;
  color: #fff !important;
  font-size: 0.875rem !important;
}

a:focus-visible {
  text-decoration: none !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

#root {
  overflow-x: hidden !important;
}

.MuiInputBase-root::after {
  border-color: #ff7900 !important;
}

/* Responsive Framework */
@media (max-width: 768px) {
  /* .MuiContainer-root {
    margin-left: 0px !important;
  }
  .MuiTypography-root {
    padding-left: 0px !important;
  } */
  .text-center-sm {
    text-align: center !important;
  }

  .banner-head {
    color: var(--black);
    font-weight: 600;
    font-family: var(--haeding-font);
    line-height: 30px !important;
    text-transform: uppercase;
  }

  .academics-list {
    font-size: 14px !important;
    padding-left: 0px;
  }

  .mob-dropdown {
    margin-left: 16px !important;
  }

  .MuiCollapse-wrapper {
    padding: 5px 10px;
  }
}

.sam_heading {
  margin: 0;
  font-family: "EB Garamond", serif;
  font-size: 1.875rem;
  line-height: 1.375;
  color: #0a0a0a;
  font-weight: 700;
  letter-spacing: 0em;
  display: block;
  margin-top: 16px;
  margin-bottom: 12px;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #0a0a0a;
  letter-spacing: -0.125px;
}

.dwld_card {
  background-color: rgba(74, 210, 149, 0.5) !important;
}
.dwld_card:hover {
  background-color: rgba(74, 210, 149, 0.2) !important;
}
.staffImg {
  height: 320px !important;
  object-fit: fill !important;
}
.mandatory-docs {
  margin: 20px !important;
}
.p-c-5 {
  padding: 3rem;
}
.slider-text {
  font-size: 15px;
}
@media (max-width: 768px) {
  .p-c-5 {
    padding: 0.25rem;
  }
  .slider-text {
    font-size: 13px !important;
  }
}
@media only screen and (max-width: 900px) {
  .image-list {
    -webkit-column-count: 3 !important;
            column-count: 3 !important;
    grid-template-columns: repeat(1, 1fr) !important;
    grid-gap: 4px;
    gap: 4px;
  }
}
.faculty-bottom-title {
  font-style: italic !important;
  font-size: 22px !important;
  text-transform: none !important;
  font-weight: 400 !important;
  text-align: center;
  padding-bottom: 32px !important;
}
.infra-last-row li {
  text-transform: none !important;
  font-size: 16px !important;
}

/* .logoPadding {
  padding-top: 25px !important;
  padding-bottom: 15px !important;
} */
.css-rfb6dd {
  background: rgb(255, 121, 0) !important;
}

.css-1vt2rd0-MuiTypography-root {
  font-weight: 500 !important;
}

.css-1f1wq2x {
  background-color: #fff !important;
}

.css-wv2xt6 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

@media (max-width: 768px) {
  .css-rfb6dd {
    background: #fff !important;
  }

  .MuiBox-root {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
.css-16y6h6u {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding: 2px !important;
}
.css-bgea9c {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding: 2px !important;
}

@media (min-width: 992px) {
  .css-lzmcx4 {
    margin-left: 0px !important;
  }
  .css-1yfne41-MuiTypography-root {
    font: size 0.65em !important;
  }
}

footer a {
  color: #fff !important;
  opacity: 0.6 !important;
}
footer a:hover {
  opacity: 1 !important;
  transition: 0.3s !important;
}
.css-gcd0ms {
  padding: 0 !important;
  margin-top: 0px !important;
}
.btn-white {
  border-radius: 0 !important;
  padding: 10px 30px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
}

.principal-message {
  font-size: 16px !important;
  text-transform: none;
  text-align: justify;
}

.page p {
  margin-bottom: 0px !important;
}
.gallery-thumb img {
  height: 300px !important;
  object-fit: cover !important;
  cursor: pointer !important;
}

.page p {
  margin-bottom: 0px !important;
}

.eventImg {
  width: 150px !important;
  height: 180px !important;
  object-fit: cover !important;
  border-radius: 5px !important;
  border: 2px solid #ccc !important;
  padding: 5px;
}

:root {
  --heading-orange: rgba(255, 121, 0, 0.6);
  --text-body: rgba(10, 10, 10, 0.8);
  --text-light: rgba(255, 255, 255, 0.8);
  --black: rgba(10, 10, 10, 1);
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --haeding-font: "EB Garamond", serif;
}
.newsBox {
  overflow: hidden !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.news-card {
  min-width: 550px;
}
.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: transparent !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.125);
}
.news-header {
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ff7900 !important;
  font-family: "EB Garamond", serif !important;
  font-family: var(--haeding-font) !important;
}
.card {
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important; */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  border: none !important;
}
.custom-card {
  background-color: rgba(10, 10, 10, 0.7) !important;
}
.clgName {
  color: #fff !important;
  text-transform: uppercase !important;
  font-family: "EB Garamond", serif !important;
  font-family: var(--haeding-font) !important;
}
.about-h1 {
  font-family: "EB Garamond", serif !important;
  font-family: var(--haeding-font) !important;
}
.esta {
  text-transform: capitalize !important;
  color: rgba(255, 255, 255, 0.8);
  color: var(--text-light);
  font-style: italic;
  font-size: 14px;
  font-weight: 400;
}
.affiliation {
  color: rgba(255, 255, 255, 0.8) !important;
  color: var(--text-light) !important;
  font-weight: 300;
}
.affiliation span {
  color: rgba(255, 255, 255, 0.8) !important;
  color: var(--text-light) !important;
  font-weight: 500;
  text-transform: uppercase;
}
.address-text {
  color: rgba(255, 255, 255, 0.8) !important;
  color: var(--text-light) !important;
  font-size: 16px;
  padding-top: 15px;
}
.about-footer {
  position: absolute;
  left: 5%;
  bottom: 5%;
  border-left: 5px solid rgba(255, 255, 255, 0.8);
}
.about-footer h1 {
  color: rgba(255, 255, 255, 0.6);
}
.about-footer h5 {
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 16px;
  line-height: 22px;
  text-shadow: 0px 4px 6px rgba(255, 255, 255, 0.25);
}
#vision h1 {
  text-transform: capitalize;
  color: rgba(10, 10, 10, 1);
  color: var(--black);
  font-family: "EB Garamond", serif;
  font-weight: 600;
}
#vision p {
  color: rgba(10, 10, 10, 0.8);
  color: var(--text-body);
  font-size: 16px;
  font-weight: 400;
}
ul li::marker {
  color: rgba(255, 121, 0, 0.6) !important;
  color: var(--heading-orange) !important; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
  font-size: 20px;
}
#admission h1 {
  text-transform: capitalize;
  color: rgba(10, 10, 10, 1) !important;
  color: var(--black) !important;
  font-family: "EB Garamond", serif;
  font-weight: 600;
}
#admission p {
  color: var(--body-text) !important;
  font-size: 16px !important;
}
#course h1 {
  font-family: "EB Garamond", serif;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8) !important;
  line-height: 50px !important;
  font-size: 45px !important;
}
#courses h1 {
  font-family: "EB Garamond", serif;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8) !important;
  text-transform: capitalize;
  font-size: 45px !important;
}
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(255, 121, 0, 1) !important;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 0.5rem 1rem;
  font-size: 1.15rem;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
#courses a {
  color: rgba(10, 10, 10, 1) !important;
  color: var(--black) !important;
  font-weight: 700;
  font-size: 14px;
}
.course-box {
  border-left: 6px solid rgba(255, 255, 255, 0.4) !important;
}
.course-box ul li {
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.8) !important;
  line-height: 3;
  font-weight: 600;
  font-size: 22px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.5s;
  cursor: pointer;
}
.course-box ul li:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  -webkit-transform-origin: 20% 40%;
          transform-origin: 20% 40%;
}
.course-box ul li::marker {
  color: transparent !important;
}
.guru {
  color: rgba(10, 10, 10, 1);
  color: var(--black);
  font-weight: 600;
  font-family: "EB Garamond", serif;
  font-family: var(--haeding-font);
  line-height: 40px !important;
}
.guruimg {
  position: absolute !important;
  right: 30%;
  z-index: 0;
  opacity: 0.3;
}
.guru-text {
  color: rgba(10, 10, 10, 0.8) !important;
  color: var(--text-body) !important;
  font-size: 16px !important;
  line-height: 28px !important;
  text-align: justify;
  font-weight: 400;
}
#footer-bottom h2 {
  color: rgba(255, 255, 255, 0.8) !important;
  color: var(--text-light) !important;
  text-transform: capitalize !important;
  font-family: "EB Garamond", serif;
  font-family: var(--haeding-font);
  font-size: 25px !important;
}
#footer-bottom p {
  color: rgba(255, 255, 255, 0.8);
  color: var(--text-light);
  font-size: 14px;
}
.footer-head {
  font-family: "EB Garamond", serif !important;
  font-family: var(--haeding-font) !important;
  color: rgba(255, 255, 255, 0.6) !important;
  font-size: 40px !important;
}
.btn-white {
  border-radius: 0 !important;
  padding: 10px 30px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
}
.fixed-top {
  position: fixed;
  top: 0;
}
.banner-head {
  color: rgba(10, 10, 10, 1);
  color: var(--black);
  font-weight: 610;
  font-family: "EB Garamond", serif;
  font-family: var(--haeding-font);
  line-height: 50px !important;
  /* text-transform: uppercase; */
}
.banner-sub {
  color: rgba(10, 10, 10, 1);
  color: var(--black);
  font-weight: 600;
  font-family: "EB Garamond", serif;
  font-family: var(--haeding-font);
  line-height: 10px !important;
}
.CollegeDetails {
  position: absolute;
  min-height: 310px;
  right: 5%;
  top: 0;
}
@media (max-width: 768px) {
  .MuiContainer-root {
    margin-left: 0px !important;
  }
  /* .MuiTypography-root {
    padding-left: 0px !important;
  } */
  .text-center-sm {
    text-align: center !important;
  }
  .banner-head {
    color: rgba(10, 10, 10, 1);
    color: var(--black);
    font-weight: 610;
    font-family: "EB Garamond", serif;
    font-family: var(--haeding-font);
    line-height: 40px !important;
    /* text-transform: uppercase; */
  }
  .bg-mob-about {
    background-image: url(/static/media/bg-about.7514d73e.webp);
    padding: 30px 0;
  }
  .news-card {
    min-width: 100%;
  }
  .text-mob-head {
    font-size: 12px !important;
  }
  .newsBox {
    overflow: hidden !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    margin-left: 10px !important;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

