footer a {
  color: #fff !important;
  opacity: 0.6 !important;
}
footer a:hover {
  opacity: 1 !important;
  transition: 0.3s !important;
}
.css-gcd0ms {
  padding: 0 !important;
  margin-top: 0px !important;
}
.btn-white {
  border-radius: 0 !important;
  padding: 10px 30px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
}
