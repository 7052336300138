.body-text {
  font-size: 16px;
  text-transform: none;
  text-align: justify;
}

.left-side {
  float: left;
  width: 40%;
  padding: 1%;
}

.loader {
  width: 180px !important;
}

.news-btn {
  background-color: #ff7900 !important;
  border-radius: 15px !important;
  padding: 5px 10px !important;
  color: #fff !important;
  font-size: 12px !important;
}

.footer-list {
  list-style: none !important;
  color: #fff !important;
  font-size: 0.875rem !important;
}

a:focus-visible {
  text-decoration: none !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

#root {
  overflow-x: hidden !important;
}

.MuiInputBase-root::after {
  border-color: #ff7900 !important;
}

/* Responsive Framework */
@media (max-width: 768px) {
  /* .MuiContainer-root {
    margin-left: 0px !important;
  }
  .MuiTypography-root {
    padding-left: 0px !important;
  } */
  .text-center-sm {
    text-align: center !important;
  }

  .banner-head {
    color: var(--black);
    font-weight: 600;
    font-family: var(--haeding-font);
    line-height: 30px !important;
    text-transform: uppercase;
  }

  .academics-list {
    font-size: 14px !important;
    padding-left: 0px;
  }

  .mob-dropdown {
    margin-left: 16px !important;
  }

  .MuiCollapse-wrapper {
    padding: 5px 10px;
  }
}

.sam_heading {
  margin: 0;
  font-family: "EB Garamond", serif;
  font-size: 1.875rem;
  line-height: 1.375;
  color: #0a0a0a;
  font-weight: 700;
  letter-spacing: 0em;
  display: block;
  margin-top: 16px;
  margin-bottom: 12px;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #0a0a0a;
  letter-spacing: -0.125px;
}

.dwld_card {
  background-color: rgba(74, 210, 149, 0.5) !important;
}
.dwld_card:hover {
  background-color: rgba(74, 210, 149, 0.2) !important;
}
.staffImg {
  height: 320px !important;
  object-fit: fill !important;
}
.mandatory-docs {
  margin: 20px !important;
}
.p-c-5 {
  padding: 3rem;
}
.slider-text {
  font-size: 15px;
}
@media (max-width: 768px) {
  .p-c-5 {
    padding: 0.25rem;
  }
  .slider-text {
    font-size: 13px !important;
  }
}
@media only screen and (max-width: 900px) {
  .image-list {
    column-count: 3 !important;
    grid-template-columns: repeat(1, 1fr) !important;
    gap: 4px;
  }
}
.faculty-bottom-title {
  font-style: italic !important;
  font-size: 22px !important;
  text-transform: none !important;
  font-weight: 400 !important;
  text-align: center;
  padding-bottom: 32px !important;
}
.infra-last-row li {
  text-transform: none !important;
  font-size: 16px !important;
}
